import styled from 'styled-components';
import { Link as RouteLink } from 'react-router-dom';

export const Container = styled.div`
  text-shadow: 1px 1px 1px white;
  width: 100%;
  overflow: hidden;
  position: relative;
  height: calc(93vh - 24px);
  display: flex;
  gap: 15px;
  align-items: center;
  flex-direction: column;
  color: black;
  z-index: 700;
`;

export const InfoWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 702;
  margin: auto;
`;

export const Height = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  gap: 15px;
  @media (max-width: 1400px) {
    gap: 5px;
  }
`;

export const Text = styled.div`
  font-size: 2rem;
  text-align: center;
`;

export const Description = styled.div`
  font-size: ${({ isMobile }) => (isMobile ? '1rem' : '1.5rem')};
  @media (max-width: 1400px) {
    font-size: ${({ isMobile }) => (isMobile ? '1rem' : '1.2rem')};
  }
  width: ${({ isMobile }) => (isMobile ? '80%' : '50%')};
  text-align: center;
  z-index: 702;
  margin: auto;
  text-shadow: 1px 1px 1px white;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.7);
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const Number = styled.div`
  font-size: ${({ isMobile }) => (isMobile ? '4rem' : '7rem')};
  @media (max-width: 1400px) {
    font-size: ${({ isMobile }) => (isMobile ? '4rem' : '5rem')};
  }
  @media (max-width: 1200px) {
    font-size: ${({ isMobile }) => (isMobile ? '4rem' : '4rem')};
  }
`;

export const Unit = styled.div`
  font-size: ${({ isMobile }) => (isMobile ? '3rem' : '5rem')};
  @media (max-width: 1400px) {
    font-size: ${({ isMobile }) => (isMobile ? '3rem' : '4rem')};
  }
  @media (max-width: 1200px) {
    font-size: ${({ isMobile }) => (isMobile ? '3rem' : '3rem')};
  }
`;

export const Links = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 0 10vh;
  z-index: 702;
  gap: 30px;
  @media (max-width: 1200px) {
    font-size: ${({ isMobile }) => (isMobile ? '10px' : '12px')};
    margin: 0 0 7vh;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
  width: 100%;
  height: 100%;
  z-index: 705;
`;

export const LineWrapper = styled.div`
  display: flex;
  height: 10vh;
  max-height: 130px;
  width: 100%;
  flex-grow: 2;
  z-index: 702;
  margin-top: 20px;
  @media (max-width: 1400px) {
    height: 5vh;
  }
  @media (max-width: 800px) {
    @media (orientation: landscape) {
      display: none;
    }
  }
`;
export const Line = styled.div`
  height: 100%;
  width: 50%;
  align-self: flex-start;
  border-right: 2px solid;
  @media (max-width: 1400px) {
    height: 70%;
  }
`;

export const Link = styled(RouteLink)`
  border: 1px solid white;
  text-shadow: none;
  padding: 10px 40px;
  color: black;
  background: white;
  text-transform: uppercase;
  :hover {
    background: black;
    color: white;
  }
`;

export const BlackLink = styled(Link)`
  border: 1px solid black;
  color: white;
  background: #18444e;
  :hover {
    background: white;
    color: black;
  }
`;

export const Iframe = styled.iframe`
  position: absolute;
  bottom: 0;
  height: 135%;
  width: 100%;
  z-index: 701;
`;
