import styled from 'styled-components';

export const Button = styled.button.attrs({ type: 'button' })`
  justify-self: center;
  align-self: center;
  display: flex;
  position: absolute;
  width: 50px;
  height: 50px;
  font-size: 25px;
  color: white;
  background: #f75a40;
  border: 1px solid white;
  visibility: hidden;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 505;
`;
