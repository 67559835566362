import styled from 'styled-components';
import { ReactComponent as HouseSvg } from 'assets/svg/Skica.svg';

export const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  height: calc(93vh - 24px);
  background-color: rgb(51, 58, 70);
  opacity: 1;
`;

export const GreySquare = styled.div`
  position: absolute;
  width: 100vh;
  height: 100vh;
  transform: rotate(45deg);
  bottom: 0;
  visibility: hidden;
  z-index: 300;
  background: rgb(51, 58, 70);
`;

export const GreyHalf = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(51, 58, 70);
  overflow: hidden;
  height: 100%;
  flex-basis: 50%;
  z-index: 320;
`;

export const WhiteHalf = styled.div`
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  flex-basis: 50%;
  z-index: 310;
`;

export const Image = styled.img.attrs({ alt: '' })`
  position: absolute;
  height: auto;
  width: 130%;
  bottom: 0;
  z-index: 330;
  opacity: 0.3;
`;

export const InfoTextWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  overflow: hidden;
  height: 100%;
  width: 50%;
  ${({ isleft }) => (isleft ? 'left: 0;' : 'right: 0;')}
`;

export const InfoTextWrapperMiddle = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  overflow: hidden;
  height: 100%;
  width: 50%;
  //color: #a9a9a9;
`;

export const InfoText = styled.div`
  text-align: center;
  color: ${({ color }) => color ?? 'white'};
  z-index: 340;
  font-weight: 600;
  opacity: 1;
  > div {
    display: flex;
    justify-content: center;
  }
  @media (max-width: 1050px) {
    @media (orientation: portrait) {
      transform: translateY(240px);
    }
  }
  @media (max-width: 600px) {
    ${({ isMiddle }) => isMiddle && 'padding-top: 50px'};
    @media (orientation: portrait) {
      transform: translateY(120px);
    }
  }
`;

export const HelperTextWrapper = styled.div`
  overflow: hidden;
  > p {
  }
`;

export const Text = styled.p`
  margin: 0 auto;
  text-transform: uppercase;
  font-size: ${({ isMobile }) => (isMobile ? '18px' : '35px')};
  ${({ isMiddle }) => isMiddle && 'color: #000'};
  ${({ isMiddle }) => isMiddle && 'font-size: 50px'};
  ${({ isMiddle }) => isMiddle && 'font-weight: bolder'};
  @media (max-width: 650px) {
    ${({ isMiddle }) => isMiddle && 'font-size: 30px'};
  }
  @media (max-width: 400px) {
    ${({ isMiddle }) => isMiddle && 'font-size: 20px'};
  }
`;

export const NumberText = styled.div`
  font-size: 20vw;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
  ${({ isMiddle }) => isMiddle && 'color: #000'};
  @media (min-width: 830px) {
    font-size: 15vw;
  }
`;

export const House = styled(HouseSvg)`
  position: absolute;
  width: 80%;
  //max-height: 80%;
  //top: -300px;
  z-index: 330;
  g {
    fill: darkgray;
  }
  @media (max-width: 1800px) {
    //top: -400px;
  }
  @media (max-width: 1500px) {
    //top: -350px;
  }
  @media (max-width: 500px) {
    top: -25vh;
  }
  @media (max-width: 350px) {
    top: -40vh;
  }
  @media (max-width: 1100px) {
    //top: -450px;
    @media (orientation: portrait) {
      //top: -400px;
    }
  }
  @media (max-width: 800px) {
    @media (orientation: portrait) {
      //top: -550px;
    }
    @media (orientation: landscape) {
      //top: -600px;
    }
  }
  @media (max-height: 800px) {
    @media (orientation: landscape) {
      //top: -80vh;
    }
  }
  @media (max-height: 500px) {
    @media (orientation: landscape) {
      //top: -140vh;
    }
  }
`;
