import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const Wrapper = styled.div`
  position: fixed;
  top: calc(9vh + 5px);
  left: 0;
  right: 0;
  bottom: 0;
`;

export const LogoInMenu = styled.div`
  height: auto;
  padding: 15px;
  width: 100px;
  margin: 20px auto;
  img {
    width: 100px;
    height: auto;
  }

  @media (min-width: 700px) {
    width: 200px;
    img {
      width: 200px;
      height: auto;
    }
  }
  @media (max-height: 500px) {
    display: none;
  }
`;

export const Container = styled.div`
  position: relative;
  z-index: 900;
  width: 100vh;
`;

export const WhiteContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  background-color: transparent;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  z-index: 901;
`;

export const WhiteSquare = styled.div`
  position: absolute;
  background-color: white;
  height: calc((100vh - 85px) * 2);
  width: calc((100vh - 85px) * 2);
  transform: rotate(45deg);
  z-index: 902;
`;

export const MenuContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgb(51, 58, 70);
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  z-index: 903;
`;

export const Links = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  gap: 32px;
  font-size: 20px;
  text-transform: uppercase;

  @media (max-height: 500px) {
    margin-top: -25px;
    gap: 15px;
  }
`;

export const Link = styled(NavLink)`
  color: white;
  pointer-events: ${({ isActive }) => isActive && 'none'};
  height: fit-content;
  width: fit-content;
  &.active {
    color: grey;
  }
  @media (min-width: 700px) {
    font-size: 23px;
  }
`;
