import { IconWithText } from 'pages/Description/IconWithText';
import React from 'react';
import { Container } from './FloatingContact.styled';

function FloatingContact({ onClick, isModalEnabled }) {
  return (
    <Container
      onClick={() => {
        if (!isModalEnabled) {
          onClick();
        }
      }}
      isModalEnabled={isModalEnabled}>
      <IconWithText icon="fa-phone" />
    </Container>
  );
}

export default FloatingContact;
