import { Arrow } from 'components/icons';
import styled from 'styled-components';

export const Wrapper = styled.div`
  height: calc(93vh - 24px);
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  z-index: 400;
  @media (min-width: 1400px) {
    justify-content: center;
  }
`;

export const Container = styled.div`
  position: relative;
  top: -50px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  z-index: 401;
`;

export const Image = styled.img.attrs({ alt: '' })`
  position: absolute;
  top: 0;
  z-index: 399;
  object-fit: cover;
`;

export const Image2 = styled.img.attrs({ alt: '' })`
  position: relative;
  height: auto;
  width: 100%;
  z-index: 403;
`;
export const Image3 = styled.img.attrs({ alt: '' })`
  position: relative;
  height: auto;
  width: 80%;
  z-index: 403;
  margin-bottom: 30px;
`;
export const TimelineSlider = styled.div`
  height: 20vh;
  width: 60%;
  background-color: transparent;
  display: flex;
  justify-content: center;
  overflow: hidden;

  @media (min-width: 767px) {
    justify-content: flex-start;
  }
  > div {
    width: 100%;

    @media (min-width: 767px) {
      width: 100%;
      justify-content: flex-start;
    }
  }
`;

export const TimelineItem = styled.div`
  height: 20vh;
  width: 100%;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border: 1px solid white;
  cursor: pointer;
  overflow: hidden;

  @media (min-width: 830px) {
    width: 33.3%;
  }
`;

export const Info = styled.div`
  height: 99.9%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.8rem;
  font-weight: bold;
  color: white;
  text-transform: uppercase;
  display: flex;
  flex-direction: column;
  :hover {
    background-color: rgb(0, 0, 0, 0.4);
    color: #f75a40;
    > span {
      color: #f75a40;
    }
  }
  > span {
    font-size: 1rem;
  }
`;
export const Description = styled.div`
  height: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  font-size: 0.8rem;
  text-transform: uppercase;
  color: rgb(51, 58, 70);
`;

export const ButtonWrapper = styled.div`
  width: 20%;
  height: 20vh;
  z-index: 410;
  display: flex;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  border-top: 1px solid white;
  border-bottom: 1px solid white;

  > div {
    width: 100%;
    height: 100%;
    background-color: transparent;
    display: flex;
    align-items: center;

    > div {
      width: 100%;
      height: 70%;
      ${({ isleft }) => (isleft ? 'border-right: 1px solid white' : 'border-left: 1px solid white')}
    }
  }
`;

export const Button = styled.button.attrs({ type: 'button' })`
  width: 50%;
  max-width: 80px;
  height: 100%;
  border: none;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 4rem;
  cursor: pointer;
  > svg {
    fill: ${({ disabled }) => (disabled ? 'gray' : 'white')};
  }
`;

export const BackArrow = styled(Arrow)`
  transform: rotate(180deg);
`;

export const WhiteSquare = styled.div`
  position: absolute;
  width: 100vh;
  height: 100vh;
  transform: rotate(45deg);
  bottom: 0;
  visibility: hidden;
  z-index: 399;
  background: linear-gradient(to bottom left, rgb(51, 58, 70) 50%, white 50%);
`;

export const AnimationSquaresWrapper = styled.div`
  position: absolute;
  height: calc(93vh - 24px);
  width: 100%;
  overflow: hidden;
  display: flex;
  visibility: hidden;
`;

export const Square = styled.div`
  visibility: visible;
  z-index: 480;
  width: 50%;
  background-color: ${({ isleft }) => (isleft ? 'white' : 'rgb(51, 58, 70)')};
`;
