export const menuAnimation = {
  whiteOne: { duration: 1, z: 90, scale: 1, xPercent: '+=100' },
  whiteTwo: { duration: 1, z: -90, scale: 1, xPercent: '-=100' },
  container: {
    duration: 0.5,
    scale: 0,
    rotationZ: -90,
    ease: 'power1.out',
    opacity: 0,
  },
};
