import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import gsap from 'gsap/gsap-core';
import { useTranslation } from 'react-i18next';
import { links } from 'containers/Header/constants';
import { Transition } from 'react-transition-group';
import Logo from '../../assets/Logo2.png';
import { LogoInMenu, Container, MenuContainer, WhiteContainer, WhiteSquare, Wrapper, Links, Link } from './Menu.styled';
import { menuAnimation } from './constants';

const Menu = ({ isVisible, toggleMenuTimeline }) => {
  const timeline = useRef(gsap.timeline({ paused: true }));
  const { t } = useTranslation();

  const squareOne = useRef(null);
  const squareTwo = useRef(null);
  const container = useRef(null);

  return (
    <Transition
      in={isVisible}
      appear
      mountOnEnter
      unmountOnExit
      timeout={1000}
      onEnter={() => {
        timeline.current
          .from(squareOne.current, menuAnimation.whiteOne, 0)
          .from(squareTwo.current, menuAnimation.whiteTwo, 0)
          .from(container.current, menuAnimation.container, 0.6)
          .play();
      }}
      onExit={() => {
        timeline.current.reversed(true);
      }}>
      <Wrapper>
        <Container>
          <WhiteContainer>
            <WhiteSquare ref={squareOne} />
            <WhiteSquare ref={squareTwo} />
          </WhiteContainer>
          <MenuContainer ref={container}>
            <Links>
              {Object.values(links).map(({ tKey, name, path, isLogo }) => {
                if (isLogo) return null;

                return (
                  <Link key={name} exact to={path} onClick={toggleMenuTimeline} activeClassName="active">
                    {t(`header.${tKey}`)}
                  </Link>
                );
              })}
            </Links>
            <LogoInMenu>
              <Link to="/" onClick={toggleMenuTimeline}>
                <img src={Logo} alt="logo" />
              </Link>
            </LogoInMenu>
          </MenuContainer>
        </Container>
      </Wrapper>
    </Transition>
  );
};

Menu.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  toggleMenuTimeline: PropTypes.func.isRequired,
};

export default Menu;
