import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  height: calc(93vh - 24px);
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: none;

  ::-webkit-scrollbar {
    width: 0px;
  }
`;
