/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, forwardRef } from 'react';
import gsap, { Back } from 'gsap/gsap-core';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';

import {
  Container,
  GreyHalf,
  WhiteHalf,
  InfoText,
  NumberText,
  HelperTextWrapper,
  GreySquare,
  Text,
  Image,
  InfoTextWrapper,
  House,
  InfoTextWrapperMiddle,
} from './Three.styled';

const animateHouse = element =>
  gsap.from(element.current, {
    yPercent: 30,
    rotationX: -90,
    duration: 1.5,
    scale: 1.5,
    autoAlpha: 0,
    ease: Back.easeInOut.config(1.2),
  });

const animateWhiteSquare = element =>
  gsap.from(element.current, {
    rotationZ: 180,
    skewY: 135,
    duration: 1.1,
    transformOrigin: 'left bottom',
    autoAlpha: 0,
    scale: 3,
  });

const animateNumber = (element, isEven) =>
  gsap.from(element, {
    y: isEven ? '-=20%' : '+=20%',
    duration: 0.8,
    autoAlpha: 0,
    delay: 1.2,
    filter: 'blur(15px)',
    autoRound: false,
  });

const animateHelperText = elements =>
  gsap.from(elements, {
    y: '-=100%',
    duration: 0.8,
    autoAlpha: 0,
    delay: 1.4,
  });

const animateSquare = element => {
  gsap.set(element.current, { scale: 0.5, autoAlpha: 1 });
  return gsap.to(element.current, {
    yPercent: '-=50',
    duration: 0.7,
    scale: 2,
  });
};

const Three = ({ isVisible, currentSlide }, ref) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const { t } = useTranslation();

  const timeline = useRef(gsap.timeline({ paused: true }));
  const greySquareTimeline = useRef(gsap.timeline({ paused: true }));

  const greySquare = useRef(null);
  const greyHalf = useRef(null);
  const whiteHalf = useRef(null);
  const house = useRef(null);
  const text = useRef([]);
  const wrapperRef = useRef(null);

  useEffect(() => {
    timeline.current
      .add(animateHouse(house), 0)
      .add(animateWhiteSquare(whiteHalf), 0)
      .add(animateNumber(text.current[0]), 0)
      .add(animateNumber(text.current[1]), 0)
      .add(animateNumber(text.current[2], true), 0)
      .add(animateHelperText([text.current[3], text.current[4]]), 0)
      .reverse();

    greySquareTimeline.current.add(animateSquare(greySquare), 0).reverse();
  }, []);

  useEffect(() => {
    if (currentSlide > 3) {
      gsap.set(wrapperRef.current, { overflow: 'hidden' });
    } else {
      gsap.set(wrapperRef.current, { overflow: 'visible' });
    }
    if (!isVisible) {
      setTimeout(() => timeline.current.progress(0), 500);
    }

    greySquareTimeline.current.reversed(!isVisible);

    timeline.current.reversed(!isVisible);
  }, [isVisible, currentSlide]);

  return (
    <div ref={wrapperRef}>
      <Container ref={ref}>
        <House ref={house} />

        <GreySquare ref={greySquare} />
        <GreyHalf ref={greyHalf} />
        <WhiteHalf ref={whiteHalf} />
        <InfoTextWrapper isleft>
          <InfoText>
            <div>
              <NumberText ref={el => (text.current[0] = el)}>4</NumberText>
            </div>
            <HelperTextWrapper>
              <Text isMobile={isMobile} ref={el => (text.current[3] = el)}>
                {t('home.sectionThree.leftText')}
              </Text>
            </HelperTextWrapper>
          </InfoText>
        </InfoTextWrapper>

        <InfoTextWrapperMiddle>
          <InfoText isMiddle>
            <HelperTextWrapper>
              <Text isMiddle isMobile={isMobile} ref={el => (text.current[3] = el)}>
                <span style={{ color: '#fff' }}>DO PRO</span>
                <span style={{ color: '#333a46' }}>MENADE</span>
              </Text>
            </HelperTextWrapper>
          </InfoText>
        </InfoTextWrapperMiddle>

        <InfoTextWrapper>
          <InfoText color="rgb(51, 58, 70);">
            <div>
              {/* <NumberText ref={el => (text.current[1] = el)}>1</NumberText> */}
              <NumberText ref={el => (text.current[2] = el)}>4</NumberText>
            </div>
            <HelperTextWrapper>
              <Text isMobile={isMobile} ref={el => (text.current[4] = el)}>
                {t('home.sectionThree.rightText')}
              </Text>
            </HelperTextWrapper>
          </InfoText>
        </InfoTextWrapper>
      </Container>
    </div>
  );
};

export default forwardRef(Three);
