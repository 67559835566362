import { useState, useRef, useCallback, useEffect } from 'react';

import { DEFAULT_INITIAL_SECTION, DEFAULT_ANIMATION_DELAY } from './constants';

function useScroll({
  // Required
  totalSections,
  // Optional
  initialSection = DEFAULT_INITIAL_SECTION,
  animationDelay = DEFAULT_ANIMATION_DELAY,
}) {
  const [currentSection, setCurrentSection] = useState(initialSection);

  const isAnimating = useRef(false);
  const isScrollingDown = useRef(false);
  const lastClientY = useRef(null);

  const handleTouchStart = useCallback(event => {
    const [clientY] = event.touches;
    lastClientY.current = clientY;
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleScroll = event => {
    event.preventDefault();

    const currentClientY = event.changedTouches?.[0].clientY;

    if (!isAnimating.current) {
      setTimeout(() => (isAnimating.current = false), animationDelay);
      if (event.deltaY > 0 || (lastClientY.current && lastClientY.current.clientY > currentClientY)) {
        isScrollingDown.current = true;

        if (currentSection < totalSections) {
          isAnimating.current = true;

          setCurrentSection(prevSlide => +prevSlide + 1);
        }
      } else if (currentSection > initialSection) {
        isAnimating.current = true;
        isScrollingDown.current = false;

        setCurrentSection(prevSlide => +prevSlide - 1);
      }
    }
  };

  useEffect(() => {
    document.addEventListener('wheel', handleScroll, { passive: false });
    document.addEventListener('touchstart', handleTouchStart);
    document.addEventListener('touchmove', handleScroll, { passive: false });

    return () => {
      document.removeEventListener('wheel', handleScroll);
      document.removeEventListener('touchstart', handleTouchStart);
      document.removeEventListener('touchmove', handleScroll);
    };
  }, [handleScroll, handleTouchStart]);

  return { currentSection, setCurrentSection, isScrollingDown };
}

export default useScroll;
