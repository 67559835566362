import React, { useEffect, useRef } from 'react';
import gsap from 'gsap/gsap-core';

import { buttonAnimation } from './constants';
import { Button } from './OpenImageButton.styled';

const animateButton = element => gsap.from(element.current, buttonAnimation);

const OpenImageButton = ({ isVisible, onClick }) => {
  const timeline = useRef(gsap.timeline({ paused: true }));
  const buttonRef = useRef(null);

  useEffect(() => {
    timeline.current
      .add(animateButton(buttonRef), 0)
      .add(gsap.to(buttonRef.current, { borderRadius: '50%', duration: 0.1 }), 0.1)
      .reverse();
  }, []);

  useEffect(() => {
    timeline.current.reversed(!isVisible);
  }, [isVisible]);

  return (
    <Button ref={buttonRef} onClick={onClick}>
      <i className="fas fa-plus" />
    </Button>
  );
};

export default OpenImageButton;
