import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';

import 'i18n';

import GlobalStyle from './global-styles';
import App from './App';
import reportWebVitals from './reportWebVitals';

/* eslint-disable */
console.log = function () {};
console.error = function () {};
console.warn = function () {};
console.info = function () {};
/* eslint-enable */

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={null}>
      <App />
    </Suspense>
    <GlobalStyle />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
