import React from 'react';

export const Close = () => (
  <path d="M12 11.293l10.293-10.293.707.707-10.293 10.293 10.293 10.293-.707.707-10.293-10.293-10.293 10.293-.707-.707 10.293-10.293-10.293-10.293.707-.707 10.293 10.293z" />
);

export const Contact = () => (
  <path d="M12 2c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10 4.486-10 10-10zm0-2c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm4.5 17.311l-1.76-3.397-1.032.505c-1.12.543-3.4-3.91-2.305-4.497l1.042-.513-1.747-3.409-1.053.52c-3.601 1.877 2.117 12.991 5.8 11.308l1.055-.517z" />
);

export const NumberTwo = () => (
  <path
    d="M4240 12794 c-25 -2 -106 -8 -180 -14 -1114 -89 -2130 -567 -2915
-1371 -545 -559 -874 -1199 -1009 -1969 -83 -465 -83 -1057 -1 -1610 98 -667
339 -1314 675 -1815 301 -448 692 -817 1170 -1105 128 -77 538 -290 559 -290
9 0 1896 2515 1906 2541 4 11 -49 41 -202 116 -245 120 -364 197 -487 313
-229 217 -347 469 -375 801 -34 394 83 721 362 1016 218 230 479 343 795 343
419 0 738 -153 969 -466 116 -157 200 -358 239 -569 26 -138 26 -499 1 -665
-58 -381 -178 -664 -450 -1070 -129 -193 -1818 -2407 -2791 -3660 -1362 -1753
-2021 -2559 -2380 -2908 l-126 -122 0 -145 0 -145 4404 0 c4186 0 4405 1 4410
18 3 9 46 170 95 357 49 187 140 529 201 760 61 231 151 571 200 755 49 184
129 489 179 677 90 337 91 344 91 458 l0 115 -1697 2 -1696 3 164 170 c1282
1329 2209 2718 2604 3900 117 351 183 645 227 1015 19 158 16 673 -6 890 -69
699 -247 1266 -556 1777 -362 596 -910 1086 -1566 1399 -553 265 -1153 415
-1914 480 -163 14 -798 27 -900 18z"
  />
);

export const House = () => (
  <>
    <path
      d="M2740 5928 l-2375 -1903 711 -3 711 -2 1276 957 c701 527 1771 1330
  2376 1785 l1101 826 602 -481 c1185 -945 1940 -1541 1848 -1457 -25 22 -44 45
  -42 51 1 6 3 26 4 45 l1 34 46 -37 c25 -21 42 -32 38 -26 -9 15 -887 758
  -1797 1522 l-704 591 -711 0 -710 0 -2375 -1902z"
    />
    <path
      d="M7520 7056 c36 -33 499 -415 517 -426 17 -11 39 1 202 120 l182 133
  47 -34 c26 -18 340 -252 697 -519 358 -267 676 -505 708 -529 32 -23 57 -44
  55 -46 -2 -2 -113 -16 -247 -30 l-244 -27 -102 -91 c-56 -51 -479 -438 -940
  -862 l-839 -770 407 -3 407 -2 422 373 c233 205 549 483 703 619 154 135 351
  309 439 386 l158 140 1142 -855 c1259 -944 1190 -893 1121 -832 -78 69 -1046
  910 -1103 958 -28 24 -49 47 -47 51 3 4 -73 71 -168 147 -94 77 -347 292 -560
  477 -292 254 -393 336 -407 331 -19 -6 -32 4 -1110 884 l-515 420 -470 0
  c-395 1 -467 -1 -455 -13z"
    />
    <path
      d="M5780 5351 l0 -368 77 -7 c97 -8 492 -8 561 0 l52 7 0 368 0 369
  -345 0 -345 0 0 -369z"
    />
    <path
      d="M6620 5351 l0 -368 53 -7 c68 -8 463 -8 560 0 l77 7 0 368 0 369
  -345 0 -345 0 0 -369z"
    />
    <path
      d="M9065 5690 c10 -11 20 -20 23 -20 3 0 -3 9 -13 20 -10 11 -20 20 -23
  20 -3 0 3 -9 13 -20z"
    />
    <path
      d="M9040 5606 c0 -2 8 -10 18 -17 15 -13 16 -12 3 4 -13 16 -21 21 -21
  13z"
    />
    <path d="M5780 4485 l0 -375 345 0 345 0 0 375 0 375 -345 0 -345 0 0 -375z" />
    <path d="M6620 4490 l0 -370 345 0 345 0 0 370 0 370 -345 0 -345 0 0 -370z" />
    <path
      d="M11210 4806 c0 -2 26 -23 58 -46 l57 -42 -50 46 c-45 40 -65 54 -65
  42z"
    />
    <path
      d="M7462 3958 c-19 -24 -62 -118 -54 -118 4 0 37 29 72 65 l64 65 -36 0
  c-19 0 -40 -6 -46 -12z"
    />
    <path
      d="M3760 1050 c-199 -10 -320 -25 -500 -60 -433 -86 -951 -250 -1618
  -515 -305 -120 -740 -309 -649 -281 303 94 1197 312 1592 390 772 151 1192
  176 1835 111 462 -48 815 -113 1830 -340 960 -214 1341 -282 1845 -331 225
  -22 784 -25 950 -6 472 55 1000 169 1682 363 379 107 990 296 981 303 -1 2
  -79 -12 -173 -31 -420 -83 -1196 -211 -1595 -262 -791 -102 -1277 -110 -1890
  -31 -408 53 -757 122 -1555 305 -929 214 -1290 285 -1715 339 -313 40 -747 60
  -1020 46z"
    />
  </>
);

export const Location = () => (
  <path d="M12 0c-4.198 0-8 3.403-8 7.602 0 6.243 6.377 6.903 8 16.398 1.623-9.495 8-10.155 8-16.398 0-4.199-3.801-7.602-8-7.602zm0 11c-1.657 0-3-1.343-3-3s1.342-3 3-3 3 1.343 3 3-1.343 3-3 3z" />
);

export const Map = () => (
  <path d="M17.545 5l-5.545-4-5.545 4-6.455-4v18l6.455 4 5.545-4 5.545 4 6.455-4v-18l-6.455 4zm-10.545 2.073l4-2.886v13.068l-4 2.885v-13.067zm6-2.886l4 2.886v13.068l-4-2.885v-13.069zm-11 .405l4 2.479v13.294l-4-2.479v-13.294zm20 13.295l-4 2.479v-13.295l4-2.479v13.295z" />
);

export const Arrow = () => <path d="M21 12l-18 12v-24z" />;

export const Gallery = () => (
  <path d="M9 12c0-.552.448-1 1.001-1s.999.448.999 1-.446 1-.999 1-1.001-.448-1.001-1zm6.2 0l-1.7 2.6-1.3-1.6-3.2 4h10l-3.8-5zm5.8-7v-2h-21v15h2v-13h19zm3 2v14h-20v-14h20zm-2 2h-16v10h16v-10z" />
);
