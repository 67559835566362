import React, { useCallback, useEffect } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import AwesomeSlider from 'react-awesome-slider';

import 'react-awesome-slider/dist/styles.css';
import 'react-awesome-slider/dist/custom-animations/cube-animation.css';

import { Close } from 'components/icons';

import { ImageGalleryWrapper, CloseButton } from './ImageSlider.styled';

const ImageSlider = ({
  // Required
  images,
  onClose,
  // Optional
  startIndex,
}) => {
  const handleKeyDown = useCallback(
    event => {
      if (event.key === 'Escape') onClose();
    },
    [onClose],
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [handleKeyDown]);

  return createPortal(
    <ImageGalleryWrapper onTouchMove={event => event.stopPropagation()} onWheel={event => event.stopPropagation()}>
      <CloseButton onClick={onClose}>
        <Close size={24} />
      </CloseButton>
      <AwesomeSlider bullets={false} selected={startIndex} animation="cubeAnimation" fillParent infinite={false}>
        {images.map(img => (
          <div data-src={img.original} />
        ))}
      </AwesomeSlider>
    </ImageGalleryWrapper>,
    document.querySelector('#gallery-root'),
  );
};

ImageSlider.defaultProps = {
  startIndex: null,
};

ImageSlider.propTypes = {
  images: PropTypes.any.isRequired, // eslint-disable-line react/forbid-prop-types
  onClose: PropTypes.func.isRequired,
  startIndex: PropTypes.number,
};

export default ImageSlider;
