import One from 'pages/Home/containers/Sections/One/One';
import Two from 'pages/Home/containers/Sections/Two/Two';
import Three from 'pages/Home/containers/Sections/Three/Three';
import Four from 'pages/Home/containers/Sections/Four/Four';
import Five from 'pages/Home/containers/Sections/Five/Five';
import Seven from 'pages/Home/containers/Sections/Seven/Seven';

export const sections = [
  { key: 1, component: One },
  { key: 2, component: Two },
  { key: 3, component: Three },
  { key: 4, component: Four },
  { key: 5, component: Five },
  { key: 6, component: Seven },
];
