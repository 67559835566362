import { ROUTES } from 'routes/constants';

export const links = {
  skyHome: {
    name: 'skyHome',
    tKey: 'skyHome',
    path: ROUTES.BASE,
  },
  apartments: {
    name: 'apartments',
    tKey: 'apartments',
    path: ROUTES.APARTMENTS,
  },
  location: {
    name: 'location',
    tKey: 'location',
    path: ROUTES.LOCATION,
  },
  home: {
    name: 'home',
    path: ROUTES.BASE,
    isLogo: true,
  },
  advantages: {
    name: 'advantages',
    tKey: 'advantages',
    path: ROUTES.ADVANTAGES,
  },
  description: {
    name: 'description',
    tKey: 'description',
    path: ROUTES.DESCRIPTION,
  },
  contact: {
    name: 'contact',
    tKey: 'contact',
    path: ROUTES.CONTACT,
  },
};

export const barAnimation = {
  top: { duration: 0.2, x: 52, rotation: 45 },
  middle: { duration: 0.2, alpha: 0 },
  bottom: { duration: 0.2, x: 52, rotation: -45 },
};

export const languages = ['sr', 'en'];

export const phoneNumber = 'tel://+38163475505';
