import CanvasDraw from 'react-canvas-draw';

import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: calc(93vh - 24px);
  overflow: hidden;
  width: 100%;
  .w-bottom-bar-lower {
    visibility: hidden !important;
  }
`;

export const SecondAnimationGroup = styled.div`
  font-family: 'BenchNine' !important;
  height: 300px;
  width: 710px;
  //width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  overflow: hidden;
  flex-direction: column;
  z-index: 300;
  color: white;
  align-items: baseline;
  transform: scale(0.45);
  @media (min-width: 500px) {
    transform: scale(0.5);
  }
  @media (min-width: 700px) {
    transform: scale(0.8);
    margin-top: 5vh;
    @media (max-height: 800px) {
      margin-top: -3vh;
    }
  }
  @media (min-width: 1050px) {
    transform: scale(0.8);
  }
  @media (min-width: 1050px) {
    @media (max-height: 800px) {
      transform: scale(0.6);
    }
  }
  /* @media (min-width: 1200px) {
    transform: scale(0.9);
  } */
  @media (min-width: 1600px) {
    transform: unset;
    transform: scale(1);
  }
  @media (min-width: 2000px) {
    transform: scale(1.3);
  }
  @media (max-height: 450px) {
    transform: scale(0.3);
    margin-top: -25vh;
  }
  h1 {
    transform: scaleY(1.2);
    text-shadow: 2px 8px 6px rgba(0, 0, 0, 0.2), 0px -5px 35px rgba(255, 255, 255, 0.3);

    //font-size: 150px;
    font-size: 80px;
    //padding-left: 120px;
    padding-left: 65px;
    margin: 0;
    white-space: nowrap;
    letter-spacing: 15px;
  }
  h3 {
    letter-spacing: 2px;
    white-space: nowrap;
    margin: 0;
    visibility: hidden;
    transform: translateY(100px);
    font-size: 80px;
    opacity: 0.8;
    letter-spacing: 6px;
  }
  div {
    width: 0;
    height: 300px;
    overflow: hidden;
  }
`;
export const StyledText = styled.div`
  visibility: hidden;
  height: 500px;
  width: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  flex-direction: column;
  margin-top: 15vh;
  z-index: 300;
  transform: scale(0.5);
  margin-top: 5vh;

  @media (min-width: 700px) {
    transform: scale(0.8);
    margin-top: 10vh;
  }
  @media (min-width: 1050px) {
    transform: scale(1);
  }
  @media (min-width: 1050px) {
    @media (max-height: 800px) {
      transform: scale(0.6);
    }
  }
  @media (min-width: 1600px) {
    transform: unset;
    margin-top: 15vh;
  }
  @media (min-width: 2000px) {
    transform: scale(1.3);
    margin-top: 20vh;
  }
  @media (max-height: 450px) {
    transform: scale(0.3);
    margin-top: -30vh;
  }

  h1 {
    font-family: 'BenchNine';

    margin: 0;
    color: #b18161;
    font-size: 100px;
    letter-spacing: 20px;
    z-index: 300;
    font-weight: bold;
    min-height: 188px;
    :nth-child(1) {
      //min-width: 214px;
      min-width: 160px;
      font-weight: bold;
      //font-size: 150px;
      font-size: 80px;
    }
    :nth-child(2) {
      //min-width: 334px;
      min-width: 150px;
      font-weight: bold;
      //font-size: 150px;
      font-size: 80px;
    }
    :nth-child(3) {
      width: 98%;
      min-height: 124px;
    }
  }
  .dark-square {
    z-index: 200;
    height: 300px;
    width: 300px;
    transform: translate(190px, -70px);
    background-color: black;
    position: absolute;
  }
  .light-square {
    opacity: 0.7;
    z-index: 200;
    height: 130px;
    width: 130px;
    transform: translate(-230px, 100px);
    background-color: lightgray;
    position: absolute;
  }
  .white-border {
    border: 20px solid white;
    height: 500px;
    width: 500px;
    position: absolute;
    z-index: 300;
  }
`;

export const ThirdAnimationGroup = styled.div`
  opacity: 0;
  align-self: flex-start;
  height: calc(93vh - 24px);
  width: 0;
  overflow: hidden;
  z-index: 500;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Canvas = styled(CanvasDraw)`
  background: none !important;
  position: absolute;
  align-self: flex-start;
  /* width: 100vw;
  height: calc(93vh - 24px); */
  object-fit: cover;
`;

export const Image = styled.img.attrs({ alt: '' })`
  ${props => props.abs}
  align-self: flex-start;
  width: 100vw;
  height: calc(93vh - 24px);
  object-fit: cover;
`;

export const ContentWrapper = styled.div`
  position: absolute;
  //top: 10%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  //display: flex;
  //justify-content: center;
  //align-items: center;
  //height: 250px;

  margin: 0 auto;
  //transform: scale(0.7);
  //@media (min-width: 700px) {
  //  transform: scale(0.8);
  //  @media (max-height: 700px) {
  //    transform: scale(0.7);
  //    margin-top: -10vh;
  //  }
  //  @media (min-height: 700px) {
  //    transform: scale(1);
  //  }
  //  @media (min-height: 1000px) {
  //    transform: scale(1.2);
  //  }
  //  @media (min-height: 1200px) {
  //    transform: scale(1.3);
  //  }
  //}
  @media (min-width: 1050px) {
    @media (orientation: portrait) {
      transform: scale(1);
      //top: 5%;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }

  @media (min-width: 1600px) {
    //transform: scale(1.3);
    //top: 10%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  @media (max-width: 800px) {
    //top: 5%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  @media (max-height: 450px) {
    //transform: scale(0.5);
    margin-top: -15vh;
  }
  @media (max-height: 400px) {
    //transform: scale(0.5);
    //top: -5%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin-top: -10vh;
  }
`;

export const Title = styled.div`
  text-shadow: 0px 4px 3px rgba(0, 0, 0, 0.4), 0px 8px 13px rgba(0, 0, 0, 0.1), 0px 18px 23px rgba(0, 0, 0, 0.1);
  font-size: 75px;
  color: white;
  text-align: center;
  white-space: normal;
  font-weight: bold;
  overflow: hidden;
`;

export const Line = styled.div`
  height: 100%;
  width: 25px;
  background: white;
  margin: 0 25px;
`;

export const Text = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 50px;
  color: white;
  width: fit-content;
`;
