import React from 'react';
import PropTypes from 'prop-types';

const svgContainer = IconBody => {
  const SVG = ({
    size = 16,
    strokeWidth = 0,
    color = '#000000',
    className = 'svgIcon',
    viewBox = '0 0 24 24',
    onClick,
  }) => {
    return (
      <svg
        width={size}
        height={size}
        fill={color}
        stroke={color}
        strokeWidth={strokeWidth}
        viewBox={viewBox}
        overflow="visible"
        className={className}
        onClick={onClick}>
        <IconBody className={className} />
      </svg>
    );
  };
  SVG.propTypes = {
    size: PropTypes.number,
    strokeWidth: PropTypes.number,
    color: PropTypes.string,
    className: PropTypes.string,
    onClick: PropTypes.func,
  };
  return SVG;
};

export default svgContainer;
