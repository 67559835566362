export const imageAnimation = {
  y: '+=40%',
  autoAlpha: 0,
  duration: 1.5,
  delay: 0.3,
};

export const textAnimation = {
  y: '+=100%',
  autoAlpha: 0,
  duration: 1.8,
};

export const hrAnimation = {
  width: '100%',
  duration: 1.3,
  delay: 1,
  autoAlpha: 0.5,
};

export const smallImageAnimation = {
  yPercent: '+=110',
  duration: 1.3,
};

export const smallTextAnimation = {
  yPercent: '-100',
  duration: 1,
};
