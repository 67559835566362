import styled from 'styled-components';
import { NavLink } from 'react-router-dom';

export const NavBar = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: sticky;
  height: 7vh;
  top: 0;
  left: 0;
  right: 0;
  background-color: #18444e;
  padding: 12px;
  z-index: 9999;
  @media (max-width: 830px) {
    justify-content: space-around;
  }
`;

export const Links = styled.div`
  display: none;
  gap: 100px;
  //gap: 0;
  text-transform: uppercase;
  align-items: center;

  @media (min-width: 830px) {
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
  }
  @media (max-width: 1920px) {
    //gap: 80px;
    gap: 50px;
  }
  @media (max-width: 1700px) {
    gap: 50px;
    //gap: 0;
  }
  @media (max-width: 1200px) {
    //gap: 25px;
    gap: 0;
  }
`;

export const Link = styled(NavLink)`
  font-family: 'BenchNine', sans-serif;

  color: white;
  pointer-events: ${({ isActive }) => isActive && 'none'};
  height: fit-content;
  text-align: center;
  font-size: 27px;

  &.active {
    color: grey;
  }
  @media (max-width: 1700px) {
    font-size: 24px;
  }
  @media (max-width: 1500px) {
    font-size: 20px;
  }
  @media (max-width: 1200px) {
    font-size: 17px;
  }
  /* @media (min-width: 830px) {
  }
  @media (max-width: 1920px) {
  }

  @media (max-width: 1200px) {
    gap: 25px;
  } */
`;

export const Hamburger = styled.svg.attrs({
  viewBox: '0 0 384 276',
  width: 32,
})`
  cursor: pointer;

  line {
    stroke: white;
    stroke-width: 32;
    stroke-linecap: round;
  }

  @media (min-width: 830px) {
    display: none;
  }
`;

export const Contact = styled.img.attrs({ alt: '' })`
  width: 52px;
  height: 52px;
  cursor: pointer;
  @media (max-width: 1400px) {
    width: 32px;
    height: 32px;
  }
  @media (max-width: 1200px) {
    width: 40px;
    height: 40px;
  }
`;

export const Languages = styled.div`
  display: flex;
  padding-left: 10px;
  gap: 5px;
  height: fit-content;
`;

export const Button = styled.button`
  font-family: 'BenchNine', sans-serif;

  background: transparent;
  border: none;
  color: ${({ isActive }) => (isActive ? 'grey' : 'white')};
  font-size: 21px;
  text-transform: uppercase;
  cursor: pointer;
  @media (max-width: 1700px) {
    font-size: 17px;
  }

  @media (max-width: 1400px) {
    font-size: 14px;
  }
  @media (max-width: 1200px) {
    font-size: 15px;
    /* @media (orientation: portrait) {
      font-size: 18px;
    } */
  }
  @media (max-width: 800px) {
    font-size: 18px;
  }
`;

export const Line = styled.div`
  height: 5vh;
  width: 1px;
  border-right: 0.5px solid #c6c7c9;
`;

export const ContactWrapper = styled.div`
  position: relative;
  min-width: 95px;
  @media (max-width: 1700px) {
    min-width: 85px;
  }
  @media (max-width: 1400px) {
    min-width: 76px;
  }
  @media (max-width: 830px) {
    min-width: unset;
  }
`;

export const DropDown = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0;
  width: 250px;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.2);
`;
export const DropDownButton = styled.button.attrs({ type: 'button' })`
  width: 100%;
  height: 70px;
  background: white;
  border: none;
  font-size: ${({ size }) => size ?? '12px'};
  border-bottom: 1px solid #eaebec;
  cursor: pointer;
  text-transform: uppercase;
  color: ${({ color }) => color ?? '#333a46'};
  display: flex;
  align-items: center;
  justify-content: center;
  > img {
    height: auto;
    width: auto;
    margin-right: 4px;
  }
`;
