import React, { useCallback, useEffect, useRef } from 'react';
import gsap, { TweenLite } from 'gsap/gsap-core';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';

import useScroll from 'services/hooks/useScroll/useScroll';

import Dots from 'components/Dots/Dots';

import { Container } from './Home.styled';

import { sections } from './constants';

gsap.registerPlugin(ScrollToPlugin);

const Home = ({ videoStarted, videoFinished, setVideoFinished, setVideoStarted }) => {
  const { currentSection, setCurrentSection } = useScroll({ totalSections: sections.length });

  const slideRefs = useRef([]);
  const containerRef = useRef(null);

  const handleNavigation = useCallback(event => setCurrentSection(+event.target.dataset.index), [setCurrentSection]);

  const handleAnimate = useCallback(slideNumber => {
    TweenLite.to(containerRef.current, 0.7, {
      scrollTo: slideRefs.current[slideNumber],
    });
  }, []);

  useEffect(() => {
    handleAnimate(currentSection);
    return () => setVideoFinished(true);
  }, [currentSection, handleAnimate, setVideoFinished]);

  const getIsWhite = () => [4, 5].some(item => item === currentSection);

  return (
    <>
      <Dots
        activeSlide={+currentSection}
        onClick={handleNavigation}
        count={sections.length}
        color={getIsWhite() ? 'white' : undefined}
      />
      <Container ref={containerRef}>
        {sections.map(({ key, component }) => {
          const Component = component;

          return (
            <Component
              setVideoStarted={setVideoStarted}
              setVideoFinished={setVideoFinished}
              videoFinished={videoFinished}
              videoStarted={videoStarted}
              key={key}
              isVisible={key === currentSection}
              ref={el => (slideRefs.current[key] = el)}
              currentSlide={currentSection}
            />
          );
        })}
      </Container>
    </>
  );
};

export default Home;
