import * as Icons from './icons';
import svgContainer from './svgContainer';

export const Close = svgContainer(Icons.Close);
export const Contact = svgContainer(Icons.Contact);
export const NumberTwo = svgContainer(Icons.NumberTwo);
export const House = svgContainer(Icons.House);
export const Location = svgContainer(Icons.Location);
export const Map = svgContainer(Icons.Map);
export const Arrow = svgContainer(Icons.Arrow);
export const Gallery = svgContainer(Icons.Gallery);
