import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Transition } from 'react-transition-group';
import { TweenLite } from 'gsap/gsap-core';

import { Container, Dot, Wrapper } from './Dots.styled';

const animateActiveDot = element => () => TweenLite.from(element.current, { duration: 0.7, scale: 0, rotate: 0 });

const Dots = ({
  // Required
  activeSlide,
  count,
  onClick,
  // Optional
  color,
}) => {
  const activeDot = useRef(null);

  const dots = [...Array(count + 1).keys()].slice(1);

  return (
    <Container>
      {dots.map(dot =>
        activeSlide === dot ? (
          <Transition
            key={dot}
            appear
            in={activeSlide === dot}
            mountOnEnter
            unmountOnExit
            timeout={700}
            onEnter={animateActiveDot(activeDot)}>
            <Wrapper ref={activeDot} color={color} onClick={onClick} data-index={dot}>
              <Dot color={color} />
            </Wrapper>
          </Transition>
        ) : (
          <Dot onClick={onClick} key={dot} data-index={dot} color={color} />
        ),
      )}
    </Container>
  );
};

Dots.defaultProps = {
  color: 'rgb(51, 58, 70)',
};

Dots.propTypes = {
  activeSlide: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  color: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

export default Dots;
