import styled from 'styled-components';
import { ReactComponent as Left } from 'assets/svg/sat.svg';
import { ReactComponent as Right } from 'assets/svg/most_slobode.svg';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: calc(93vh - 24px);
  background-color: white;
  width: 100%;
`;

export const WhiteSquare = styled.div`
  position: absolute;
  align-self: center;
  width: 100vh;
  height: 100vh;
  transform: rotate(45deg);
  bottom: 0;
  visibility: hidden;
  z-index: 199;
  background: white;
`;

export const Heading = styled.div`
  font-size: 1rem;
  color: grey;
  line-height: 1.2;
  width: 70%;
  //padding-top: 15vh;
  padding-top: 5vh;
  text-align: center;
  z-index: 200;
  @media (orientation: portrait) {
    font-size: 1.3rem;
  }
  @media (min-width: 700px) {
    @media (min-height: 600px) {
      font-size: 1.3rem;
    }
  }
  @media (min-width: 1000px) {
    @media (orientation: portrait) {
      font-size: 1.8rem;
    }
  }
  @media (min-width: 1300px) {
    font-size: 1.8rem;
  }
  @media (max-height: 600px) {
    padding-top: 3vh;
    font-size: 1rem;
  }
  @media (max-height: 400px) {
    padding-top: 3vh;
  }
  @media (max-width: 350px) {
    padding-top: 2vh;
    font-size: 1rem;
  }
`;

export const Image = styled.img.attrs({ alt: '' })`
  position: absolute;
  bottom: 0;
  z-index: 230;
  width: 70%;
  height: auto;
  max-width: 800px;

  @media only screen and (min-width: 1000px) {
    width: 60%;
    max-width: 1000px;
  }
  @media only screen and (min-width: 1600px) {
    width: 70%;
    max-width: 1200px;
  }
  @media (max-width: 1900px) {
    width: 60%;
    max-width: 1200px;
    @media (orientation: landscape) {
      width: 55%;
    }
  }
  @media (max-width: 600px) {
    position: fixed;
    width: 115%;
  }
  @media (min-width: 700px) {
    @media (orientation: portrait) {
      width: 120%;
    }
  }
`;

export const Hr = styled.hr`
  position: absolute;
  width: 0%;
  bottom: 10vh;
  border: 2px solid grey;
  visibility: hidden;
  margin: 0;
  z-index: 200;
`;

export const WhiteContainer = styled.div`
  height: 10vh;
  width: 100%;
  background-color: white;
  z-index: 220;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const SvgLeft = styled(Left)`
  width: 15%;
  height: auto;
  max-height: 180px;
  position: absolute;
  bottom: calc(10vh + 2px);
  ${({ isleft }) => (isleft ? 'left: 25px' : 'right: 25px')};
  z-index: 210;
  opacity: 0.5;
  @media (min-width: 830px) {
    width: 15%;
  }
  @media (max-width: 600px) {
    @media (orientation: portrait) {
      display: none;
    }
    width: 14px;
  }
  @media (min-width: 700px) {
    @media (orientation: portrait) {
      display: none;
    }
  }
`;
export const SvgRight = styled(Right)`
  width: 20%;
  height: auto;
  max-height: 180px;
  position: absolute;
  bottom: calc(10vh + 2px);
  ${({ isleft }) => (isleft ? 'left: 25px' : 'right: 25px')};
  z-index: 210;
  opacity: 0.5;
  @media (min-width: 830px) {
    width: 16%;
  }
  @media (max-width: 600px) {
    @media (orientation: portrait) {
      display: none;
    }
    width: 14px;
  }
  @media (min-width: 700px) {
    @media (orientation: portrait) {
      display: none;
    }
  }
`;

export const TextWrapper = styled.div`
  width: 20%;
  text-align: center;
  font-size: 12px;
  overflow: hidden;
  margin-top: 15px;
  ${({ isleft }) => (isleft ? 'margin-left: 25px' : 'margin-right: 25px')};
  @media (min-width: 767px) {
    font-size: 14px;
    width: 15%;
    margin-left: 25px;
  }
  @media (max-width: 600px) {
    display: none;
  }
`;

export const Text = styled.div`
  color: grey;
  font-size: 12px;
  @media (min-width: 767px) {
    font-size: 14px;
  }
`;
