import React, { useCallback, useEffect } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';

import 'react-awesome-slider/dist/styles.css';
import 'react-awesome-slider/dist/custom-animations/cube-animation.css';

import { Close } from 'components/icons';

import { YoutubeWrapper, CloseButton } from './ImageSlider.styled';

const YoutubeSlider = ({
  // Required
  onClose,
  // Optional
}) => {
  const handleKeyDown = useCallback(
    event => {
      if (event.key === 'Escape') onClose();
    },
    [onClose],
  );

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [handleKeyDown]);

  return createPortal(
    <YoutubeWrapper onTouchMove={event => event.stopPropagation()} onWheel={event => event.stopPropagation()}>
      <CloseButton onClick={onClose}>
        <Close size={24} />
      </CloseButton>
      <iframe
        title="dinamika-gradnje"
        width={window.innerWidth}
        height={window.innerWidth / 1.6}
        src="https://www.youtube.com/embed/7xIDqfhBZao"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </YoutubeWrapper>,
    document.querySelector('#gallery-root'),
  );
};

YoutubeSlider.defaultProps = {
  startIndex: null,
};

YoutubeSlider.propTypes = {
  images: PropTypes.any.isRequired, // eslint-disable-line react/forbid-prop-types
  onClose: PropTypes.func.isRequired,
  startIndex: PropTypes.number,
};

export default YoutubeSlider;
