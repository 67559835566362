import React from 'react';
import styled from 'styled-components';

const StyledDiv = styled.div`
  //color: #18444e;
  color: white;
  width: 80%;
  //border: black 4px solid;
  display: flex;
  justify-content: left;
  align-items: center;

  i {
    font-size: 30px;
    color: #b18161;
  }
  p {
    font-size: 16px;
    margin: 0;
    text-shadow: 3px 3px 3px black;
  }
  div {
    margin: 10px;
    min-width: 70px;
    display: flex;
    justify-content: center;
  }
  @media (max-width: 350px) {
    p {
      line-height: 16px;
    }
  }
  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {
    i {
      font-size: 25px;
    }
    p {
      font-size: 18px;
    }
  }

  @media (min-width: 992px) {
    i {
      font-size: 35px;
    }
    p {
      font-size: 25px;
    }
    @media (max-height: 700px) {
      p {
        font-size: 15px;
      }
    }

    @media (min-width: 1200px) {
      i {
        font-size: 50px;
      }
      p {
        font-size: 30px;
      }
      @media (max-height: 700px) {
        i {
          font-size: 30px;
        }
        p {
          font-size: 25px;
        }
      }
    }
  }
`;

export const IconWithText = ({ icon, text, iconToTop }) => (
  <StyledDiv iconToTop={iconToTop}>
    <div>
      <i className={`fas ${icon}`} />
    </div>
    <p>{text}</p>
  </StyledDiv>
);
