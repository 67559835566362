import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  right: 15px;
  top: calc(50vh - 40px);
  display: flex;
  flex-direction: column;
  gap: 15px;
  z-index: 9999;
`;

export const Dot = styled.div`
  width: 8px;
  height: 8px;
  background-color: ${({ color }) => color};
  border-radius: 50%;
  margin: 7px;
  cursor: pointer;
  @media (max-width: 1200px) {
    width: 5px;
    height: 5px;
    margin: 5px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid ${({ color }) => color};
  transform: rotate(45deg) translate(-2px, 2px);
  @media (max-width: 1200px) {
  }
  cursor: pointer;
`;
