import styled from 'styled-components';

export const ImageGalleryWrapper = styled.div`
  z-index: 99999;
  position: fixed;
  height: 100%;
  width: 100%;
  background: black;
  display: flex;
  align-items: center;
  .awssld__content img {
    object-fit: contain;
  }

  /* .awssld {
    height: 88%;
  } */

  .awssld__controls {
    visibility: visible;
  }
`;
export const YoutubeWrapper = styled.div`
  z-index: 99999;
  position: fixed;
  width: 100%;
  height: 100%;
  background: black;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const CloseButton = styled.button.attrs({ type: 'button' })`
  position: absolute;
  top: 15px;
  right: 15px;
  background-color: transparent;
  border: none;
  z-index: 506;
  cursor: pointer;

  > svg {
    fill: white;
  }
`;
