export const images = [
  { name: 'first', src: `${process.env.PUBLIC_URL}/images/home-section-five/1.jpg`, top: 0, number: 23 },
  {
    name: 'second',
    src: `${process.env.PUBLIC_URL}/images/home-section-five/2.jpg`,
    top: 0,
    isEven: true,
    number: 24,
  },
  { name: 'third', src: `${process.env.PUBLIC_URL}/images/home-section-five/3.jpg`, top: 50, isEven: true, number: 0 },
  { name: 'fourth', src: `${process.env.PUBLIC_URL}/images/home-section-five/4.jpg`, top: 50, number: 5 },
];

export const imageAnimation = {
  y: '+=100%',
  duration: 1,
  scale: 1.3,
  autoAlpha: 0,
};
export const galleryImages = [
  { original: `${process.env.PUBLIC_URL}/images/home-section-five/1.jpg` },
  { original: `${process.env.PUBLIC_URL}/images/home-section-five/2.jpg` },
  { original: `${process.env.PUBLIC_URL}/images/home-section-five/3.jpg` },
  { original: `${process.env.PUBLIC_URL}/images/home-section-five/4.jpg` },
  { original: `${process.env.PUBLIC_URL}/images/home-section-five/5.jpg` },
  { original: `${process.env.PUBLIC_URL}/images/home-section-five/6.jpg` },
  { original: `${process.env.PUBLIC_URL}/images/home-section-five/7.jpg` },
  { original: `${process.env.PUBLIC_URL}/images/home-section-five/8.jpg` },
  { original: `${process.env.PUBLIC_URL}/images/home-section-five/9.jpg` },
  { original: `${process.env.PUBLIC_URL}/images/home-section-five/10.jpg` },
  { original: `${process.env.PUBLIC_URL}/images/home-section-five/11.jpg` },
  { original: `${process.env.PUBLIC_URL}/images/home-section-five/12.jpg` },
  { original: `${process.env.PUBLIC_URL}/images/home-section-five/13.jpg` },
  { original: `${process.env.PUBLIC_URL}/images/home-section-five/14.jpg` },
  { original: `${process.env.PUBLIC_URL}/images/home-section-five/15.jpg` },
  { original: `${process.env.PUBLIC_URL}/images/home-section-five/16.jpg` },
  { original: `${process.env.PUBLIC_URL}/images/home-section-five/17.jpg` },
  { original: `${process.env.PUBLIC_URL}/images/home-section-five/18.jpg` },
  { original: `${process.env.PUBLIC_URL}/images/home-section-five/19.jpg` },
  { original: `${process.env.PUBLIC_URL}/images/home-section-five/20.jpg` },
];

// for (let index = 1; index < 7; index++) {
//   galleryImages.push({ original: `${process.env.PUBLIC_URL}/images/${index}.png` });
// }
