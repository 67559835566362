import React, { forwardRef, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import gsap, { TweenLite } from 'gsap/gsap-core';

import { ROUTES } from 'routes/constants';

import {
  Container,
  TitleWrapper,
  Description,
  Number,
  Unit,
  Height,
  Text,
  Links,
  Line,
  Link,
  LineWrapper,
  InfoWrapper,
  Iframe,
  ContentWrapper,
  BlackLink,
} from './Seven.styled';

const animateLine = element => TweenLite.from(element, { height: '0%', duration: 0.5 });

const animateText = elements => TweenLite.from(elements, { autoAlpha: 0, duration: 1, scale: 0.5, stagger: 0.2 });

const animateLinks = elements =>
  TweenLite.from(elements, {
    duration: 0.5,
    autoAlpha: 0,
    stagger: 0.2,
    yPercent: '+=200',
    rotateZ: 90,
    rotateY: 90,
    xPercent: -20,
  });

const animateImage = element => TweenLite.from(element, { yPercent: '+=25', duration: 3 });

const Seven = ({ isVisible }, ref) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery({ maxWidth: 767 });

  const timeline = useRef(gsap.timeline({ paused: true }));

  const imageRef = useRef(null);
  const lineRefs = useRef([]);
  const textRefs = useRef([]);
  const linksRefs = useRef([]);

  useEffect(() => {
    timeline.current
      .add(animateLine(lineRefs.current[0]))
      .add(animateText(textRefs.current))
      .add(animateLine(lineRefs.current[1]))
      .add(animateLinks(linksRefs.current), 1.5)
      .add(animateImage(imageRef.current), 0)
      .reverse();
  }, []);

  useEffect(() => {
    if (!isVisible) {
      setTimeout(() => timeline.current.progress(0), 500);
    }

    timeline.current.reversed(!isVisible);
  }, [isVisible]);

  return (
    <Container ref={ref}>
      {/* <Image src={ImageUrl} alt="" ref={imageRef} /> */}
      {/* <Iframe
        scrolling="no"
        title="panorama"
        src="https://kuula.co/share/7Vz1Q?fs=0&vr=0&sd=1&gyro=0&autorotate=0.16&thumbs=2&inst=0&info=0&logo=-1"
      /> */}
      <Iframe
        scrolling="no"
        title="panorama"
        src="https://kuula.co/share/NLf4t?fs=0&vr=0&sd=1&gyro=0&autorotate=0.3&thumbs=2&inst=0&info=0&logo=-1"
      />
      <ContentWrapper>
        <LineWrapper>
          <Line ref={el => (lineRefs.current[0] = el)} />
        </LineWrapper>
        <InfoWrapper>
          <TitleWrapper>
            <Height ref={el => (textRefs.current[0] = el)}>
              <Number isMobile={isMobile}>3.5</Number>
              <Unit isMobile={isMobile}>m</Unit>
            </Height>
            <Text ref={el => (textRefs.current[1] = el)}>{t('home.sectionSeven.headingText')}</Text>
          </TitleWrapper>
          <Description isMobile={isMobile} ref={el => (textRefs.current[2] = el)}>
            {t('home.sectionSeven.cta')}
            <br />
            {t('home.sectionSeven.newsletter')}
            <BlackLink to={ROUTES.CONTACT} ref={el => (linksRefs.current[0] = el)}>
              {t('header.contactUs')}
            </BlackLink>
          </Description>
        </InfoWrapper>
        <LineWrapper>
          <Line ref={el => (lineRefs.current[1] = el)} />
        </LineWrapper>
        <Links>
          <Link to={ROUTES.APARTMENTS} ref={el => (linksRefs.current[0] = el)}>
            {t('header.apartments')}
          </Link>
          <Link to={ROUTES.LOCATION} ref={el => (linksRefs.current[1] = el)}>
            {t('header.location')}
          </Link>
          <Link to={ROUTES.ADVANTAGES} ref={el => (linksRefs.current[2] = el)}>
            {t('header.advantages')}
          </Link>
        </Links>
      </ContentWrapper>
    </Container>
  );
};

export default forwardRef(Seven);
