import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  height: calc(93vh - 24px);
  z-index: 500;
`;

export const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  height: calc(93vh - 24px);
  background-color: rgb(51, 58, 70);
  opacity: 1;
  z-index: 501;
`;

export const ImageWrapper = styled.div`
  z-index: 502;
  position: relative;
  display: flex;
  justify-content: center;
  width: ${({ isEven }) => (isEven ? '40%' : '60%')};
  height: 50%;
  overflow: hidden;
`;

export const Image = styled.img.attrs({
  alt: 'Image',
  'object-fit': 'contain',
})`
  z-index: 503;
  opacity: 0.7;
  object-fit: cover;
  width: 110%;
  height: 110%;
  transition: 0.5s ease-in-out;

  :hover {
    opacity: 1;
    -moz-transform: translate(-10px, -10px);
    -ms-transform: translate(-10px, -10px);
    -o-transform: translate(-10px, -10px);
    -webkit-transform: translate(-10px, -10px);
    transform: translate(-10px, -10px);
  }
`;

export const WhiteSquare = styled.div`
  position: absolute;
  width: 100vh;
  height: 100vh;
  transform: rotate(45deg);
  bottom: 0;
  visibility: hidden;
  z-index: 499;
  background: linear-gradient(to bottom left, white 50%, rgb(51, 58, 70) 50%);
`;

export const AnimationSquaresWrapper = styled.div`
  position: absolute;
  height: calc(93vh - 24px);
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  visibility: hidden;
`;

export const Square = styled.div`
  visibility: visible;
  z-index: 555;
  width: 50%;
  height: 50%;
  background-color: ${({ isleft }) => (isleft ? 'rgb(51, 58, 70)' : 'white')};
`;
