import React, { useEffect, useRef, forwardRef, useState, useCallback } from 'react';
import { Transition } from 'react-transition-group';
import gsap, { TweenLite } from 'gsap/gsap-core';

import { galleryImages } from 'pages/Home/containers/Sections/Five/constants';
import ImageSlider from 'components/ImageSlider/ImageSlider';
import OpenImageButton from 'components/OpenImageButton/OpenImageButton';

import { Wrapper, Container, ImageWrapper, Image, WhiteSquare, Square, AnimationSquaresWrapper } from './Five.styled';

import { images, imageAnimation } from './constants';

const animateImage = elements => gsap.from(elements, imageAnimation);

const animateSquare = element => {
  gsap.set(element.current, { scale: 0, autoAlpha: 1 });
  return gsap.to(element.current, {
    y: '-=50%',
    scale: 1.5,
    duration: 0.7,
    immediateRender: false,
  });
};

const Five = ({ isVisible, currentSlide }, ref) => {
  const timeline = useRef(gsap.timeline({ paused: true }));
  const whiteSquareTimeline = useRef(gsap.timeline({ paused: true }));

  const firstRef = useRef(null);
  const secondRef = useRef(null);
  const thirdRef = useRef(null);
  const fourthRef = useRef(null);
  const whiteSquare = useRef(null);
  const wrapperRef = useRef(null);
  const topLeftSquare = useRef(null);
  const topRightSquare = useRef(null);
  const bottomLeftSquare = useRef(null);
  const bottomRightSquare = useRef(null);

  const [isSliderOpen, setIsSliderOpen] = useState(false);
  const [hoveredImage, setHoveredImage] = useState(null);
  const [openImageIndex, setOpenImageIndex] = useState(0);

  const handleImageMouseEnter = useCallback(event => setHoveredImage(event.currentTarget.dataset.name), []);

  const handleImageMouseLeave = useCallback(() => setHoveredImage(null), []);

  const toggleSlider = useCallback(() => setIsSliderOpen(prevState => !prevState), []);

  const refsMap = {
    first: firstRef,
    second: secondRef,
    third: thirdRef,
    fourth: fourthRef,
  };

  useEffect(() => {
    timeline.current
      .add(animateImage([firstRef.current, secondRef.current, thirdRef.current, fourthRef.current]), 0)
      .reverse();

    whiteSquareTimeline.current.add(animateSquare(whiteSquare), 0).reverse();
  }, []);

  useEffect(() => {
    if (currentSlide > 5) {
      gsap.set(wrapperRef.current, { overflow: 'hidden' });
    } else {
      gsap.set(wrapperRef.current, { overflow: 'visible' });
    }
    if (!isVisible) {
      setTimeout(() => timeline.current.progress(0), 500);
    }

    whiteSquareTimeline.current.reversed(!isVisible);

    timeline.current.reversed(!isVisible);
  }, [isVisible, currentSlide]);

  return (
    <Wrapper ref={wrapperRef}>
      <Transition
        in={isVisible}
        mountOnEnter
        unmountOnExit
        onEnter={() => {
          TweenLite.to(topLeftSquare.current, { duration: 1, xPercent: -100, yPercent: -100, delay: 0.5 });
          TweenLite.to(topRightSquare.current, { duration: 1, xPercent: 100, yPercent: -100, delay: 0.5 });
          TweenLite.to(bottomLeftSquare.current, { duration: 1, xPercent: -100, yPercent: 100, delay: 0.5 });
          TweenLite.to(bottomRightSquare.current, { duration: 1, xPercent: 100, yPercent: 100, delay: 0.5 });
        }}
        timeout={1000}
        onExit={() => {
          TweenLite.to(topLeftSquare.current, { duration: 0.3, xPercent: 0, yPercent: 0 });
          TweenLite.to(topRightSquare.current, { duration: 0.3, xPercent: 0, yPercent: 0 });
          TweenLite.to(bottomLeftSquare.current, { duration: 0.3, xPercent: 0, yPercent: 0 });
          TweenLite.to(bottomRightSquare.current, { duration: 0.3, xPercent: 0, yPercent: 0 });
        }}>
        <AnimationSquaresWrapper>
          <Square isleft ref={topLeftSquare} />
          <Square ref={topRightSquare} />
          <Square isleft ref={bottomLeftSquare} />
          <Square ref={bottomRightSquare} />
        </AnimationSquaresWrapper>
      </Transition>
      <WhiteSquare ref={whiteSquare} />
      <Container ref={ref}>
        {images.map(({ name, src, top, isEven }) => (
          <ImageWrapper
            key={name}
            top={top}
            ref={refsMap[name]}
            isEven={isEven}
            data-name={name}
            onMouseEnter={handleImageMouseEnter}
            opnMouseLeave={handleImageMouseLeave}>
            <Image src={src} />
            <OpenImageButton
              isVisible={hoveredImage === name}
              onClick={() => {
                const index = galleryImages.findIndex(g => g.original === src);
                setOpenImageIndex(index);
                toggleSlider();
              }}
            />
          </ImageWrapper>
        ))}
      </Container>
      {isSliderOpen && <ImageSlider images={galleryImages} onClose={toggleSlider} startIndex={openImageIndex} />}
    </Wrapper>
  );
};

export default forwardRef(Five);
