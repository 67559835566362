import React, { forwardRef, useRef, useEffect } from 'react';
import gsap, { TweenLite } from 'gsap/gsap-core';
import { useTranslation } from 'react-i18next';
import { Transition } from 'react-transition-group';

import {
  Container,
  Text,
  Image,
  Hr,
  WhiteContainer,
  Heading,
  TextWrapper,
  SvgRight,
  SvgLeft,
  WhiteSquare,
} from './Two.styled';
import House from './images/House.png';

import { imageAnimation, textAnimation, hrAnimation, smallImageAnimation, smallTextAnimation } from './constants';

const animateElement = (element, animationProps) => TweenLite.from(element.current, animationProps);

const animateHr = (element, animationProps) => TweenLite.to(element.current, animationProps);

const Two = ({ isVisible, currentSlide }, ref) => {
  const { t } = useTranslation();
  const timeline = useRef(gsap.timeline({ paused: true }));

  const wrapperRef = useRef(null);
  const whiteSquare = useRef(null);
  const textRef = useRef(null);
  const image = useRef(null);
  const smallImages = useRef([]);
  const hr = useRef(null);
  const smallTextRefs = useRef([]);

  useEffect(() => {
    timeline.current
      .add(animateElement(image, imageAnimation), 0)
      .add(animateElement(textRef, textAnimation), 0)
      .add(animateHr(hr, hrAnimation), 0)
      .add(animateElement(smallImages, smallImageAnimation))
      .add(animateElement(smallTextRefs, smallTextAnimation), 2.5)
      .reverse();
  }, []);

  useEffect(() => {
    if (currentSlide > 2) {
      gsap.set(wrapperRef.current, { overflow: 'hidden' });
    } else {
      gsap.set(wrapperRef.current, { overflow: 'visible' });
    }
    if (!isVisible) {
      setTimeout(() => timeline.current.progress(0), 500);
    }

    if (isVisible) {
      gsap.set(image.current, { zIndex: 999, delay: 0.7 });
    } else {
      setTimeout(() => timeline.current.progress(0), 500);
      gsap.set(image.current, { zIndex: 230 });
    }
    timeline.current.reversed(!isVisible);
  }, [isVisible, ref, currentSlide]);

  return (
    <div ref={wrapperRef}>
      <Container ref={ref}>
        <Transition
          in={isVisible}
          mountOnEnter
          unmountOnExit
          onEnter={() => {
            TweenLite.set(whiteSquare.current, { scale: 0.5, autoAlpha: 1 });
            TweenLite.to(whiteSquare.current, {
              yPercent: '-=50',
              duration: 1,
              scale: 2,
            });
          }}
          timeout={1300}
          onExit={() => {
            TweenLite.to(whiteSquare.current, {
              yPercent: 0,
              duration: 1,
              scale: 0,
            });
          }}>
          <WhiteSquare ref={whiteSquare} />
        </Transition>

        <Heading ref={textRef}>{t('home.sectionTwo.text')}</Heading>
        <Image src={House} ref={image} />
        <Hr ref={hr} />

        <SvgLeft isleft ref={el => (smallImages.current[0] = el)} />
        <SvgRight ref={el => (smallImages.current[1] = el)} />
        <WhiteContainer>
          <TextWrapper>
            <Text isleft ref={el => (smallTextRefs.current[0] = el)}>
              {t('home.sectionTwo.textLeft')}
            </Text>
          </TextWrapper>
          <TextWrapper>
            <Text ref={el => (smallTextRefs.current[1] = el)}>{t('home.sectionTwo.textRight')}</Text>
          </TextWrapper>
        </WhiteContainer>
      </Container>
    </div>
  );
};

export default forwardRef(Two);
