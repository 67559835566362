export const timelineMonths = [
  // { key: 1, path: 'home.sectionFour.monthFour', year: 2022, url: 'https://www.youtube.com/embed/7xIDqfhBZao' },
  { key: 1, path: 'Maj', year: 2022, url: 'https://www.youtube.com/embed/7xIDqfhBZao' },
  { key: 2, path: 'Avgust', year: 2022 },
  { key: 3, path: 'home.sectionFour.monthEleven', year: 2022 },
];

export const onHoverAnimation = { height: '80%', duration: 0.5 };

export const onMouseLeaveAnimation = { height: '100%', duration: 0.5 };
